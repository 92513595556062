import React from 'react';
import { headerCase, snakeCase } from 'change-case';
import { Chip } from '@material-ui/core';

import Language from '../../constants/languages';

const Chips = ({audio, language}) => {
  if (language === Language.UR.name) {
    return (
      <div>
        {audio.urduTags &&
          audio.urduTags.slice(null, 5).map((tag, index) =>
            <Chip
              className={snakeCase(audio.englishTags[index])}
              key={index}
              variant='outlined'
              label={tag}
              size="small"
            />
          )}
      </div>
    );
  }

  return (
    <div>
      {audio.englishTags &&
        audio.englishTags.slice(null, 5).map((tag) =>
          <Chip
            className={snakeCase(tag)}
            key={snakeCase(tag)}
            variant='outlined'
            label={headerCase(tag)}
            size="small"
          />
        )
      }
    </div>
  );
}

export default Chips;