import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { CircularProgress, Container, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import ReactGA from 'react-ga4';

import Language from '../../constants/languages';
import AudioSummary from '../audios/AudioSummary';

class Audios extends Component {
  state = {
    tag: '',
    searchString: ''
  };
  handleTagChange = (e, tag) => {
    this.setState({
      tag
    });
  };
  handleSearchChange = (e) => {
    this.setState({
      searchString: e.target.value.toLowerCase()
    })
  }
  render() {
    ReactGA.send({ hitType: 'pageview', page: '/audios' });

    const { firestoreApi, language } = this.props;
    const { ordered: { audios: propsAudios }, status } = firestoreApi;
    const audios = propsAudios && propsAudios.length > 0 ? propsAudios : [];
    const tags = [];
    const tagLabels = {};
    audios &&
      audios.forEach((audio) =>
        audio.englishTags.forEach((tag, index) => {
          if (!tags.includes(tag)) {
            tags.push(tag);
            tagLabels[tag] = language === Language.UR.name ? audio.urduTags[index] : audio.englishTags[index];
          }
        })
      );
    const filteredAudios =
      audios &&
      audios.filter((audio) => {
        if (this.state.tag) {
          return audio.englishTags.includes(this.state.tag) && audio.englishTitle.toLowerCase().includes(this.state.searchString);
        }
        return audio.englishTitle.toLowerCase().includes(this.state.searchString);
      });
    const txtNotFound = language === Language.UR.name ? 'کوئی آڈیو نہیں ملا' : 'No audios found';

    return (
      <Container className='Audios'>
        <Grid container justifyContent='center' alignItems='center' spacing={1}>
          <Grid container item lg={12} spacing={1}>
            <Grid item md={3} sm={6} xs={12}>
              <Autocomplete
                options={tags}
                getOptionLabel={(option) => tagLabels[option]}
                renderInput={(params) => (
                  <TextField {...params} label='Category' variant='outlined' size="small" />
                )}
                onChange={(e, tag) => this.handleTagChange(e, tag)}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <TextField type="search" id="search" label="Search" variant="outlined" fullWidth size="small" onChange={this.handleSearchChange} />
            </Grid>
          </Grid>
          <Grid container item lg={12} spacing={2}>
            {(!filteredAudios || filteredAudios.length === 0) && (
              <Grid container className='progressContainer' item justifyContent='center'>
                {status.requesting.audios ?
                  <CircularProgress /> :
                  <Alert severity="error">{txtNotFound}</Alert>
                }
              </Grid>
            )
            }
            {
              filteredAudios && filteredAudios.length > 0 &&
              filteredAudios.map((audio) => (
                <Grid item lg={3} md={4} sm={6} xs={12} key={audio.id}>
                  <AudioSummary audio={audio} />
                </Grid>
              ))
            }
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  firestoreApi: state.firestore,
  language: state.language.language
});

export default compose(connect(mapStateToProps), firestoreConnect([{ collection: 'audios' }]))(Audios);
