import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import UploadAudio from '../audios/UploadAudio';

class Upload extends Component {
	render() {
		const { auth } = this.props;
		if (!auth.uid) return <Navigate to='/signin' />;
		return <UploadAudio />;
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth
	};
};

export default connect(mapStateToProps)(Upload);
