import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { headerCase } from 'change-case';
import { AppBar, CssBaseline, Drawer, Hidden, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Switch, Toolbar, Typography} from '@material-ui/core';
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Language from '../../constants/languages';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
}));
const ResponsiveDrawer = (props) => {
  const { handleThemeChange, words, setLanguage } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (e) => {
    setAnchorEl(null);
    setLanguage(e.target.getAttribute('value'));
  };
  const drawer = (
    <div>
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary={words[1]} />
        </ListItem>
        <ListItem button component={Link} to="/contact">
          <ListItemText primary={words[2]} />
        </ListItem>
        {/* <ListItem button component={Link} to="/audios">
          <ListItemText primary={words[3]} />
        </ListItem> */}
        <ListItem>
          <Switch onChange={handleThemeChange} color='default' />
          {words[7]}
        </ListItem>
        <ListItem button>          
          <ListItemText primary='Language' onClick={handleClick} />
          <Menu open={anchorEl ? true : false} onClose={handleClose} anchorEl={anchorEl}>
            <MenuItem onClick={handleMenuItemClick} value={Language.EN.name}>
              {headerCase(Language.EN.name)}
            </MenuItem>
            <MenuItem onClick={handleMenuItemClick} value={Language.UR.name}>
              {headerCase(Language.UR.name)}
            </MenuItem>
          </Menu>
        </ListItem>
      </List>
    </div>
  );
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {words[0]}
          </Typography>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
              <CloseIcon />
            </IconButton>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}
ResponsiveDrawer.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
};
export default ResponsiveDrawer;