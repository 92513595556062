import authReducer from './authReducer';
import audioReducer from './audioReducer';
import languageReducer from './languageReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

const rootReducer = combineReducers({
	auth: authReducer,
	audio: audioReducer,
	language: languageReducer,
	firestore: firestoreReducer,
	firebase: firebaseReducer
});

export default rootReducer;
