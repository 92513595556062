import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uploadAudio } from '../../store/actions/audioActions';
import firebase from 'firebase/app';
import FileUploader from 'react-firebase-file-uploader';
import { TextField, Typography, Paper, Grid, Container, Dialog, Button, Fab, AppBar, Toolbar, IconButton, Chip, Box, Divider } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
class UploadAudio extends Component {
	state = {
		englishTitle: '',
		englishInformation: '',
		urduTitle: '',
		urduInformation: '',
		audio: '',
		audioURL: '',
		englishTags: [],
		urduTags: [],
		tagsDialog: false,
		englishTagList: ['Advice','Ahl al-Bayt (Family of the Prophet)','Ahl al-Bidʿah (People of Innovation)','Ahl al-Sunnah (People of Sunnah)','al-Asmaaʾ wa-l-Ṣifāt (Names & Attributes)','Allāh\'s Aid','al-Walāʾ wa-l-Barāʾ (Allegiance and Disavowal)','Amulets','Argumentation','Atheism','Awliyāʾ (Allies of Allāh)','Beard','Birthday Celebrations','Blind-Following','Books','Bribery','Brotherhood','Charity','Children','Mercy','Dāʿī (Caller to Allah)','Dealings with Non-Muslims','Death','Deception','Democracy','Demonstrations','Disbelievers','Disobedience','Divorce','Duʿā (Supplication)','Dunyā (Worldly Life)','Durūd ','Enjoining Good & Forbidding Evil','Evil Eye','Family','Fiqh (Islamic Jurisprudence)','Following the Sunnah','Fortune Telling','Free-Mixing','Friendship','Funeral','Gifts','Guidance','Ḥalāl (Permissible)','Happiness','Heart','Hellfire','Hereafter','Ḥijāb (Covering)','Ḥizbiyyah (Partisanship)','Husband & Wife','ʿĪd (Celebration)','Idols ','Ignorance','Ikhlāṣ (Sincerity to Allah)','ʿIlm al-Ghayb (Knowledge of the Unseen)','Īmān (Faith)','Imitation of the Disbelievers ','Innovations','Jamāʿah al-Tablīgh','Jinn','Khutbah (Friday Sermon)','Manners','Marriage','Migration','Music','Paradise','Parents','Patience','Photography','Prayer','Pride','Prophets & Messengers','Qadr (Decree)','Qurʾān','Ramaḍān','Reliance','Ribā (Usury)','Righteous Actions','Rights','Riyāʾ (Showing-Off)','Rizq (Sustenance)','Scholars','Seeking Forgiveness','Seeking Knowledge','Shīʿa','Shirk (Polytheism)','Sīrah (Prophetic Biography)','Smoking','Steadfastness','Taqwā (Religious Piety)','Tawassul (Seeking Nearness to Allah)','Tawbah (Repentance)','Tawḥīd (Monotheism)','Ties of Kinship','Trials','Trust','Truthfulness','Wisdom','Women','Worship','Zakāh (Obligatory Charity)','Zinā (Adultery/Fornication)','Zuhud (Absention)'],
		urduTagList: ['نصیحت','اہل بیت','اہل بدعت','اہل سنت','الاسماء والصفات','اللہ کی مدد','دوستی ودشمنی','تعویذ','بحث','بے دينی','اولیاء','داڑھی','جشن عید میلاد','اندھی تقلید','کتابیں','رشوت','بھائی چارہ','صدقہ','بچے','رحم','مبلغ','غیر مسلموں کے ساتھ تعلقات','موت','دھوکہ','جمہوریت','احتجاج','کفار','نافرمانی','طلاق','دعاء','دنیا','درود','نیکی کا حکم کرنا اور برائی سے روکنا','نظربد','خاندان','فقہ','سنت کی پیروی','قسمت کا حال','اختلاط','دوستی','جنازہ','تحائف','ہدایت','حلال','خوشی','دل','جہنم','آخرت','پردہ','تنظیم سازی','شوہر بیوی','عید','اصنام','جہالت','اخلاص','علم غیب','ایمان','کفار کی مشابہت','بدعت','تبلیغی جماعت','جن','خطبہ','آداب','شادی - نکاح','ہجرت','موسیقی','جنت','والدین','صبر','تصویر سازی','نماز','تکبر','انبیاء اور پیغمبرو','تقدیر','قرآن','رمضان','توکل','سود','نیک اعمال','حقوق','ریاءکاری','روزی','اہل علم','مغفرت','حصول علم','شیعہ','شرک','سیرت','تمباکو نوشی','استقامت','تقوی','وسیلہ','توبہ','توحید','رشتہ داری','آزمائش','اعتماد','سچائی','حکمت','خواتین','عبادت','زکوٰۃ','بدکاری - فحاشی','زہد']
	};
	handleTagSelect = (e) => {
		const { englishTags, urduTags, urduTagList } = this.state;
		const tag = e.target.innerText;
		if (!englishTags.includes(tag)) {
			this.setState({
				englishTags: [ ...englishTags, tag ]
			}, () => {
				this.setState({
					urduTags: [	...urduTags, urduTagList[this.state.englishTagList.indexOf(tag)] ]
				})
			})
		}
	};
	handleTagDelete = (e) => {
		const {englishTagList, urduTagList} = this.state;
		const value = e.target.parentElement.parentElement.querySelector('.MuiChip-label').innerText;
		const englishTags = this.state.englishTags.filter((tag) => tag !== value);
		const urduTags = this.state.urduTags.filter((tag) => tag !== urduTagList[englishTagList.indexOf(value)]);
		this.setState({
			englishTags,
			urduTags
		});
	};
	handleTagsDialogOpen = () => {
		this.setState({
			tagsDialog: true
		});
	};
	handleTagsDialogClose = () => {
		this.setState({
			tagsDialog: false
		});
	};
	handleChange = (e) => {
		this.setState({
			[e.target.id]: e.target.value
		});
	};
	handleUploadStart = (filename) => {
		this.setState({
			audio: filename
		});
	};
	handleUploadSuccess = (filename) => {
		this.setState({
			audio: filename
		});
		firebase.storage().ref('audios').child(filename).getDownloadURL().then((audioURL) =>
			this.setState({
				audioURL
			})
		);
	};
	handleSubmit = (e) => {
		e.preventDefault();
		const {englishTitle, urduTitle, englishInformation, urduInformation, englishTags, urduTags, audio, audioURL} = this.state;
			this.props.uploadAudio({englishTitle, urduTitle, englishInformation, urduInformation, englishTags, urduTags, audio, audioURL});
			this.setState({
				englishTitle: '',
				urduTitle: '',
				englishInformation: '',
				urduInformation: '',
				englishTags: [],
				urduTags: [],
				audio: '',
				audioURL: ''
			});
	};
	render() {
		return (
			<div className='UploadAudio' style={{ margin: '50px 0' }}>
				<Container maxWidth='md'>
					<Paper style={{ padding: '50px' }}>
						<Typography variant='h5'>Upload A New Audio</Typography>
						<form onSubmit={this.handleSubmit}>
							<Grid container spacing={3} alignItems='center'>
								<Grid item sm={12}>
									<Box my={2}>
										<div>
										  {/* <label style={{backgroundColor: '#444040', color: 'white', padding: 10, cursor: 'pointer', marginRight: '5px', width: '50px'}}>
												Choose File */}
												<FileUploader randomizeFilename required accept='audio/*' name='audio' storageRef={firebase.storage().ref('audios')} onUploadStart={this.handleUploadStart} onUploadSuccess={this.handleUploadSuccess} />
											{/* </label>  */}
											{/* <p style={{display: 'inline'}}>{this.state.audio.name ? this.state.audio.name : 'No File Chosen'}</p> */}
										</div>
									</Box>
								</Grid>
								<Grid item sm={6}>
									<TextField type='text' id='englishTitle' onChange={this.handleChange} required value={this.state.englishTitle} variant='outlined' label='English Title' fullWidth />
								</Grid>
								<Grid item sm={6}>
									<TextField type='text' id='urduTitle' onChange={this.handleChange} required value={this.state.urduTitle} variant='outlined' label='Urdu Title' fullWidth />
								</Grid>
								<Grid item sm={6}>
									<TextField label='English Information (Optional)' multiline rows={6} variant='outlined' id='englishInformation' onChange={this.handleChange} value={this.state.englishInformation} fullWidth />
								</Grid>
								<Grid item sm={6}>
									<TextField label='Urdu Information (Optional)' multiline rows={6} variant='outlined' id='urduInformation' onChange={this.handleChange} value={this.state.urduInformation} fullWidth />
								</Grid>
								<Grid item sm={2}>
									<Typography variant='body1'>Tags:</Typography>
								</Grid>
								<Grid item sm={8}>
									{this.state.englishTags.length > 0 ? (
										<div>
											<div>
												<Typography variant='body1' component='span' style={{ marginRight: '15px' }}>
													English Tags:{' '}
												</Typography>
												{this.state.englishTags.map((tag) => {
													return <Chip label={tag} variant='outlined' />;
												})}
											</div>
											<div>
												<Box my={3}>
													<Typography variant='body1' component='span' style={{ marginRight: '15px' }}>
														Urdu Tags:{' '}
													</Typography>
													{this.state.urduTags.map((tag) => {
														return <Chip label={tag} variant='outlined' />;
													})}
												</Box>
											</div>
										</div>
									) : (
										<Typography variant='body1'>No Tags Selected</Typography>
									)}
								</Grid>
								<Grid item sm={2}>
									<Fab color='secondary' onClick={this.handleTagsDialogOpen}>
										<EditIcon />
									</Fab>
									<Dialog fullScreen open={this.state.tagsDialog} onClose={this.handleTagsDialogClose}>
										<AppBar position='relative'>
											<Toolbar>
												<IconButton edge='start' color='inherit' onClick={this.handleTagsDialogClose}>
													<CloseIcon />
												</IconButton>
												<Typography variant='h6' style={{ marginRight: 'auto' }}>
													Tags
												</Typography>
												<Button color='inherit' onClick={this.handleTagsDialogClose}>
													Save
												</Button>
											</Toolbar>
										</AppBar>
										<Container>
											<Box my={5}>
												<Paper>
													<Box p={5}>
														{this.state.englishTags.length > 0 ? (
															<div>
																<Box mb={3}>
																	<Typography variant='body1'>Selected Tags:</Typography>
																</Box>
																{this.state.englishTags.map((tag) => <Chip variant='outlined' label={tag} onDelete={this.handleTagDelete} />)}
															</div>
														) : (
															<Typography variant='body1'>No Tags Selected</Typography>
														)}
														<Box my={3}>
															<Divider />
														</Box>
														{this.state.englishTagList.map((tag) => <Chip variant='outlined' label={tag} onClick={this.handleTagSelect} />)}
													</Box>
												</Paper>
											</Box>
										</Container>
									</Dialog>
								</Grid>
								<Grid item sm={12}>
								<Divider />
								<br /><br />
									{this.state.audio &&
									this.state.audioURL && 
									this.state.audioURL.includes(this.state.audio) && 
									this.state.englishTags.length > 0 &&
								  this.state.englishTitle &&
									this.state.urduTitle ? 
									<TextField type='submit' value='Upload' /> : 
									<Typography variant='body1'>You must upload an audio, select at least 1 tag and fill all fields marked with an * to continue <br /> This may take a few minutes</Typography>}
								</Grid>
							</Grid>
						</form>
					</Paper>
				</Container>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		uploadAudio: (audio) => dispatch(uploadAudio(audio))
	};
};

export default connect(null, mapDispatchToProps)(UploadAudio);
