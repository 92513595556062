import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAW_4FXAsHV_0-R0r-4AHlHaHomFDW3rnU",
  authDomain: "shaykhbadee-5926d.firebaseapp.com",
  databaseURL: "https://shaykhbadee-5926d.firebaseio.com",
  projectId: "shaykhbadee-5926d",
  storageBucket: "shaykhbadee-5926d.appspot.com",
  messagingSenderId: "748715160200",
  appId: "1:748715160200:web:acf470b6e9e6bb00cee53d",
  measurementId: "G-RBQD9NWZ74"
};
firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;