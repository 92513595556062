import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Button, CircularProgress, Container, Grid, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Link, useParams } from 'react-router-dom';
import { ChevronLeftRounded } from '@material-ui/icons';
import AudioPlayer from 'react-h5-audio-player';
import ReactGA from 'react-ga4';

import 'react-h5-audio-player/lib/styles.css';

import Language from '../../constants/languages';
import Chips from '../audios/Chips';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class AudioDetails extends Component {

  render() {
    const { audioId } = this.props.params;
    const { firestoreApi, language } = this.props;
    const { ordered: { audios: propsAudios }, status } = firestoreApi;
    const audios = propsAudios && propsAudios.length > 0 ? propsAudios : [];
    const audio = audios.find(({ id }) => id === audioId);

    ReactGA.send({ hitType: 'pageview', page: `/audios/${audioId}` });

    const txtNotFound = language === Language.UR.name ? 'آڈیو نہیں ملا' : 'Audio not found';
    const btnTxtBack = language === Language.UR.name ? 'واپس جائیں' : 'Back to Audios';
    const buttons = (
      <Button className={language === Language.UR.name && 'urduNastaliq'} component={Link} to="/audios" variant="contained" color="primary" startIcon={language === Language.EN.name && <ChevronLeftRounded />} endIcon={language === Language.UR.name && <ChevronLeftRounded />}>
        {btnTxtBack}
      </Button>
    );

    if (!audio) {
      ReactGA.event({
        category: 'Error',
        action: 'Firebase',
        label: `Audio not found: ${audioId}`
      });

      return (
        <Container className='AudioDetails'>
          <Grid container justifyContent='center' alignItems='center' spacing={4}>
            <Grid item justifyContent='flex-start' lg={12} xs={12}>
              {buttons}
            </Grid>
            <Grid container className='progressContainer' item justifyContent='center'>
              {status.requesting.audios ?  
                <CircularProgress /> :                
                <Alert severity="error">{txtNotFound}</Alert>
              }
            </Grid>
          </Grid>
        </Container>
      );
    }

    const onPlay = (event) => {
      ReactGA.event({
        category: 'Audio',
        action: 'onPlay',
        label: `Audio ID: ${audioId}`
      });
    }

    const onPause = (event) => {
      ReactGA.event({
        category: 'Audio',
        action: 'onPause',
        label: `Audio ID: ${audioId}`
      });
    }

    const onEnded = (event) => {
      ReactGA.event({
        category: 'Audio',
        action: 'onEnded',
        label: `Audio ID: ${audioId}`
      });
    }

    const onSeeking = (event) => {
      ReactGA.event({
        category: 'Audio',
        action: 'onSeeking',
        label: `Audio ID: ${audioId}`
      });
    }

    const onSeeked = (event) => {
      ReactGA.event({
        category: 'Audio',
        action: 'onSeeked',
        label: `Audio ID: ${audioId}`
      });
    }

    const onVolumeChange = (event) => {
      ReactGA.event({
        category: 'Audio',
        action: 'onVolumeChange',
        label: `Audio ID: ${audioId}`
      });
    }

    const onError = (error) => {
      ReactGA.event({
        category: 'Error',
        action: 'onError',
        label: `Audio ID: ${audioId}`
      });
    }

    const onPlayError = (error) => {
      ReactGA.event({
        category: 'Error',
        action: 'onPlayError',
        label: `Audio ID: ${audioId}`
      });
    }

    return (
      <Container className='AudioDetails'>
        <Grid container justifyContent='center' alignItems='center' spacing='4'>
          <Grid container item justifyContent='flex-start'>
            {buttons}
          </Grid>
          <Grid container item lg={12}>
            {language === Language.UR.name &&
              <Typography className='urduNastaliq' variant='h2'>{audio.urduTitle}</Typography>
            }
            {language === Language.EN.name &&
              <Typography variant='h4'>{audio.englishTitle}</Typography>
            }
          </Grid>
          <Grid container item lg={12}>
            <Chips audio={audio} language={language} />
          </Grid>
        </Grid>
        {/* <audio id="myAudio" data-author="name" data-title="name" src={audio.audioURL} controls style={{ width: 250, height: 40 }} /> */}
        <AudioPlayer
          autoPlay
          autoPlayAfterSrcChange
          onEnded={onEnded}
          onError={onError}
          onPause={onPause}
          onPlay={onPlay}
          onPlayError={onPlayError}
          onSeeked={onSeeked}
          onSeeking={onSeeking}
          onVolumeChange={onVolumeChange}
          showFilledVolume
          showJumpControls={false}
          src={audio.audioURL}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  firestoreApi: state.firestore,
  language: state.language.language
});

export default compose(connect(mapStateToProps), firestoreConnect([{ collection: 'audios' }]))(withParams(AudioDetails));
