const Language = {
  AR: {
    name: 'arabic',
    char2: 'ar',
    char3: 'ara',
  },
  EN: {
    name: 'english',
    char2: 'en',
    char3: 'eng',
  },
  UR: {
    name: 'urdu',
    char2: 'ur',
    char3: 'urd',
  }
};

export default Language;