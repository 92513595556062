import Language from '../../constants/languages';

const initState = {
	language: Language.EN.name
};

const languageReducer = (state = initState, action) => {
	switch (action.type) {
		case 'SET_LANGUAGE':
			return {
				language: action.language
			};
		default:
			return state;
	}
};

export default languageReducer;
