import React, { useState } from 'react';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import ResponsiveDrawer from './components/layout/ResponsiveDrawer';
import SignIn from './components/auth/SignIn';
import Audios from './components/pages/Audios';
import AudioDetails from './components/pages/AudioDetails';
import Contact from './components/pages/Contact';
import Upload from './components/pages/Upload';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, Hidden } from '@material-ui/core';
import { connect } from 'react-redux';
import { setLanguage } from './store/actions/languageActions';
import Language from './constants/languages';
import CookieConsent from 'react-cookie-consent';
import ReactGA from 'react-ga4';

function App(props) {
	const { language, setLanguage } = props;
	let words;
	if (language === Language.EN.name) {
    words = [ 'Shaykh Muhammad Badee Uddeen Shah', 'HOME', 'CONTACT', 'AUDIOS', 'UPLOAD', 'LOGIN', 'LOGOUT', 'Light/Dark' ];
	} else if (language === Language.UR.name) {
		words = [
			'شیخ بدیع الدین شاہ',
			'گھر',
			'رابطہ کریں',
			'آڈیوز',
			'اپ لوڈ کریں',
			'لاگ ان کریں',
			'لاگ آوٹ',
			'روشنی / سیاہ'
		];
	}
	const [ darkState, setDarkState ] = useState(false);
	const palletType = darkState ? 'dark' : 'light';
	const darkTheme = createTheme({
		palette: {
			type: palletType
		}
	});
	const handleThemeChange = () => {
		setDarkState(!darkState);
  };
  const onAcceptCookieConsent = (acceptedByScrolling) => {
    ReactGA.event({
      category: 'CookieConsent',
      action: 'accepted',
      label: acceptedByScrolling ?
        'Accept was triggered by user scrolling' :
        'Accept was triggered by clicking the Accept button'
    });
  };

  const txtCookieConsent = language === Language.UR.name ?
    'یہ ویب سائٹ کوکیز (گوگل، تلاش، تجزیات، وغیرہ) کا استعمال کرتی ہے تاکہ یہ یقینی بنایا جا سکے کہ آپ کو ہماری ویب سائٹ پر بہترین تجربہ حاصل ہے۔' :
    'This website uses cookies (Google, Search, Analytics, etc.) to ensure you get the best experience on our website.';
  const txtCookieConsentLink = language === Language.UR.name ? 'اور جانیے' : 'Learn more';
  const txtCookieConsentButton = language === Language.UR.name ? 'میں سمجھتا ہوں۔' : 'I understand';

	return (
		<BrowserRouter>
      <div className={`${palletType} App`}>
        <CookieConsent
          buttonText={txtCookieConsentButton}
          onAccept={onAcceptCookieConsent}
        >
          {txtCookieConsent}&nbsp;
          <a href="https://www.cookiesandyou.com/" rel="noopener noreferrer" target="_blank">{txtCookieConsentLink}</a>
        </CookieConsent>
				<ThemeProvider theme={darkTheme}>
					<CssBaseline />
          <Hidden xsDown>
            <Navbar handleThemeChange={handleThemeChange} words={words} setLanguage={setLanguage} />
          </Hidden>
          <Hidden smUp>
            <ResponsiveDrawer handleThemeChange={handleThemeChange} words={words} setLanguage={setLanguage} />
          </Hidden>
					<Routes>
            <Route exact path='/' element={<Audios />} />
            <Route path='/signin' element={<SignIn />} />
            <Route path='/audios' element={<Audios />} />
            <Route path='/audios/:audioId' element={<AudioDetails />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/upload' element={<Upload />} />
					</Routes>
				</ThemeProvider>
			</div>
		</BrowserRouter>
	);
}

const mapStateToProps = (state) => {
	return {
		language: state.language.language
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		setLanguage: (language) => dispatch(setLanguage(language))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
