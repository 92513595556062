export const uploadAudio = (audio) => {
	return (dispatch, getState, { getFirestore }) => {
		const firestore = getFirestore();
		firestore
			.collection('audios')
			.add({
				...audio,
				date: new Date()
			})
			.then(() => {
				dispatch({ type: 'CREATE_AUDIO_SUCCESS' });
			})
			.catch((err) => {
				dispatch({ type: 'CREATE_AUDIO_ERROR' }, err);
			});
	};
};
