const initState = {};

const audioReducer = (state = initState, action) => {
	switch (action.type) {
		case 'CREATE_AUDIO_SUCCESS':
			console.log('create audio success');
			return state;
		case 'CREATE_AUDIO_ERROR':
			console.log('create audio error');
			return state;
		case 'GET_AUDIOS_BY_CATEGORY_SUCCESS':
			console.log('get audios by category success');
			return {
				...state,
				audios: action.audios
			};
		case 'GET_AUDIOS_BY_CATEGORY_ERROR':
			console.log('get audios by category error', action.err);
			return state;
		default:
			return state;
	}
};

export default audioReducer;
