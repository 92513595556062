import React from 'react';
import { Container, Typography, Box } from '@material-ui/core';

const Contact = () => {
	return (
		<Container maxWidth='md'>
			<Box my={5}>
				<Typography variant='h4'>Contact</Typography>
        <Typography variant='p'>Email: <a href="mailto:info@shaykhbadee.com">info@shaykhbadee.com</a></Typography>
			</Box>
		</Container>
	);
};

export default Contact;
