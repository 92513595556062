import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Language from '../../constants/languages';
import Chips from './Chips';

const AudioSummary = ({ audio, language }) => {
	const url = '/audios/' + audio.id;
  let template;

	if (language === Language.EN.name) {
		template = (
			<div>
				<Typography variant='h5'>{audio.englishTitle}</Typography>
				<p>{audio.englishInformation}</p>
			</div>
		);
	}
	if (language === Language.UR.name) {
		template = (
			<div>
        <Typography variant='h5' className='urduNastaliq'>{audio.urduTitle}</Typography>
				<p>{audio.urduInformation}</p>
			</div>
		);
	}
  
	return (
		<div className='AudioSummary'>
			<Link to={url} className='no-style-link'>
				<Paper style={{ padding: 20 }}>
					{template}
          <Chips audio={audio} language={language} />
          {audio.englishTags.length - 5 <= 0 ? null : <p>And {audio.englishTags.length - 5} more</p>}
          {/* <audio id='audio-element' src={audio.audioURL} controls style={{width: 250, height: 40}} /> */}
				</Paper>
			</Link>
		</div>
	);
};

const mapStateToProps = (state) => ({
  language: state.language.language
});

export default connect(mapStateToProps)(AudioSummary);
