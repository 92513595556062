import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { headerCase } from 'change-case';
import { AppBar, Toolbar, Typography, Container, Switch, Button, Menu, MenuItem } from '@material-ui/core';
import Language from '../../constants/languages';
import { signOut } from '../../store/actions/authActions';

const Navbar = (props) => {
	const { auth, handleThemeChange, words, setLanguage, signOut } = props;
	const [ anchorEl, setAnchorEl ] = React.useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleMenuItemClick = (e) => {
		setAnchorEl(null);
		setLanguage(e.target.getAttribute('value'));
	};
	return (
		<AppBar position='static' color='primary'>
			<Container>
				<Toolbar>
					<Typography variant='h6' style={{ marginRight: 'auto' }}>
						{words[0]}
					</Typography>
					<div className='nav-links'>
						<Link to='/'>{words[1]}</Link>
						<Link to='/contact'>{words[2]}</Link>
						{/* <Link to='/audios'>{words[3]}</Link> */}
						{auth.uid ? <Link to='/upload'>{words[4]}</Link> : null}
						{auth.uid ? (
							<Link to='/' onClick={signOut}>
								{words[6]}
							</Link>
						) : null}
						{/* {auth.uid ? null : <Link to='/signin'>{words[5]}</Link>} */}
            <Switch onChange={handleThemeChange} color='default' />
						{words[7]}
						<Link to='#'>
							<Button style={{ color: 'white' }} onClick={handleClick}>
								Language
							</Button>
						</Link>
						<Menu open={anchorEl ? true : false} onClose={handleClose} anchorEl={anchorEl}>
              <MenuItem onClick={handleMenuItemClick} value={Language.EN.name}>
                {headerCase(Language.EN.name)}
							</MenuItem>
              <MenuItem onClick={handleMenuItemClick} value={Language.UR.name}>
                {headerCase(Language.UR.name)}
							</MenuItem>
						</Menu>
					</div>
				</Toolbar>
			</Container>
		</AppBar>
	);
};

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		signOut: () => dispatch(signOut())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
