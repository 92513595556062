import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signIn } from '../../store/actions/authActions';
import { Navigate } from 'react-router-dom';
import { Container, Typography, TextField, Button } from '@material-ui/core';

class SignIn extends Component {
	state = {
		email: '',
		password: ''
	};
	handleChange = (e) => {
		this.setState({
			[e.target.id]: e.target.value
		});
	};
	handleSubmit = (e) => {
		e.preventDefault();
		this.props.signIn(this.state);
	};
	render() {
		const { authError, auth } = this.props;
		if (auth.uid) return <Navigate to='/' />;
		return (
			<div className='SignIn'>
				<Container maxWidth='xs' style={{ padding: '60px 0' }}>
					<Typography variant='h5' style={{ textAlign: 'center' }}>
						Sign In
					</Typography>
					<form onSubmit={this.handleSubmit}>
						<TextField
							type='email'
							id='email'
							label='Email'
							variant='outlined'
							required
							onChange={this.handleChange}
							fullWidth
						/>
						<TextField
							type='password'
							id='password'
							label='Password'
							variant='outlined'
							required
							onChange={this.handleChange}
							fullWidth
						/>
						<Button color='primary' variant='contained' onClick={this.handleSubmit} fullWidth>
							Login
						</Button>
						<div>
							{authError ? (
								<Typography variant='body1' color='red'>
									{authError}
								</Typography>
							) : null}
						</div>
					</form>
				</Container>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authError: state.auth.authError,
		auth: state.firebase.auth
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		signIn: (creds) => dispatch(signIn(creds))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
